<template>
    <form v-if="brands && legalEntities">
        <div class="mb-3 text-h3">{{$t('actions.invitecashier.title')}}</div>

        <v-select 
            v-if="displayBrands.length != 1"
            :label="$t('labels.brand')"
            :items="displayBrands"
            item-text="name"
            item-value="id"
            v-model="brandId">
        </v-select>

        <v-text-field :label="$t('actions.invitecashier.emailOfCashier')" v-model="email"></v-text-field>

        <v-btn :disabled="inviting" :loading="inviting" color="primary" @click="invite">{{$t('actions.invitecashier.inviteBtn')}}</v-btn>

    </form>
</template>

<script>
export default {
    data() {
        return {
            email: null,
            brandId: null, 
            inviting: false
        }
    },
    computed: {
        legalEntities() {
            return this.$store.getters.legalEntities
        },
        brands() {
            return this.$store.getters.brands
        },
        displayBrands() {
            if (this.legalEntities.length > 1) {
                return this.brands.map(p => ({
                id: p.id,
                name: `${p.name} (${this.legalEntities.find(le => le.id == p.legalEntityId).name})`
                }))
            }  
            return this.brands
        },
        selectedBrand() {
            if (this.brands.length == 1)
                return this.brands[0]
            return this.brands.find(p => p.id == this.brandId)
        }
    },
    methods: {
        invite() {
            if (!this.selectedBrand) {
                alert("Selecteer een brand!")
                return;
            }   

            this.inviting = true
            this.$api.addCashier(this.selectedBrand.legalEntityId, this.selectedBrand.id, this.email)
                .then(() => { 
                    this.$showSuccess("Kassier werd uitgenodigd!") 
                    this.$emit('ok')
                })
                .finally(() => { this.inviting = false })
        }
    }
}
</script>
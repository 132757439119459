<template>

    <div>

      <base-material-card 
        icon="mdi-account-group"
        :title="$t('labels.overview')"
        class="px-5 py-3"
      >
        <v-data-table
          :headers="headers"
          :items="cashiers"
          class="elevation-0"
          :loading="loading"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.firstName }} {{ props.item.lastName }}</td>
              <td>{{ props.item.email }} </td>
            </tr>
          </template>
        </v-data-table>
      </base-material-card>

      <v-btn @click="addCashier" color="primary">{{$t('actions.invitecashier.addBtn')}}</v-btn>

    </div>

</template>


<script>
import AddCashier from './addCashier'
export default {

    data() {
      return {
        cashiers: [],
        loading: false
      }
    },

    computed: {
        headers() {
            return [
                { text: this.$t('labels.name'), value: 'firstName' },
                { text: this.$t('labels.email'), value: 'email' }
            ]
        }  
    },

    async mounted() {
        this.loading = true
        this.cashiers = await this.$api.getCashiers()
        this.loading = false
    },
    methods: {
        addCashier() {
            this.$openModal(AddCashier, null, this)
              .then(() => this.$api.getCashiers().then((c) => this.cashiers = c)) // refresh afterwards
        }
    }

}
</script>